import React from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";

import Header from "./components/header";
import loadable from "@loadable/component";

import Login from "./pages/login";
import Sidebar from "./components/sidebar";
import FindMy from "./services/auth";
import CreateUser from "./pages/user/create";
import UpdateUser from "./pages/user/update";
import CreateOffice from "./pages/office/create";
import UpdateOffice from "./pages/office/update";
import CircularProgress from "@mui/material/CircularProgress";
import { FUNCTIONALITIES } from "./constants/functionalities";

const Home = loadable(() => import("./pages/home"));
const User = loadable(() => import("./pages/user"));
const Office = loadable(() => import("./pages/office"));
const Logs = loadable(() => import("./pages/settings/logs"));
const Profile = loadable(() => import("./pages/settings/profile"));

const CreateImportH7Declaration = loadable(() =>
  import("./pages/import/tiantian_h7/index")
);
const DeclarationsH7Table = loadable(() =>
  import("./pages/import/tiantian_h7/edit")
);
const Translations = loadable(() => import("./pages/settings/translations"));

const Duties = loadable(() => import("./pages/settings/duties"));

const routesList = {
  create_user: {
    title: "Create new user",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/user",
        isActive: false,
      },
      {
        Name: "New user",
        Link: "/user/create",
        Title: "Create new user",
        isActive: true,
      },
    ],
  },
  user: {
    title: "List of users",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/user",
        Title: "List of users",
        isActive: true,
      },
    ],
  },
  update_user: {
    title: "Update user",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/user",
        isActive: false,
      },
      {
        Name: "Update user",
        Link: "/user/:id",
        Title: "Update user",
        isActive: true,
      },
    ],
  },
  dashboard: {
    title: "Dashboard",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: true,
      },
    ],
  },
  office: {
    title: "List of offices",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Office",
        Link: "/office",
        Title: "List of offices",
        isActive: true,
      },
    ],
  },
  update_office: {
    title: "Update office",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Office",
        Link: "/office",
        isActive: false,
      },
      {
        Name: "Update office",
        Link: "/office/:id",
        Title: "Update office",
        isActive: true,
      },
    ],
  },
  create_office: {
    title: "Create new office",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Office",
        Link: "/office",
        isActive: false,
      },
      {
        Name: "New office",
        Link: "/office/create",
        Title: "Create new office",
        isActive: true,
      },
    ],
  },
  create_import_h7: {
    title: "Create Import H7 Declaration",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Create Import H7 Declaration",
        Link: "/create_import_h7",
        Title: "Create Import H7 Declaration",
        isActive: true,
      },
    ],
  },
  declarations_table_h7: {
    title: "Declarations H7 Table",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Create Import H7 Declaration",
        Link: "/create_import_h7",
        Title: "Create Import H7 Declaration",
        isActive: false,
      },
      {
        Name: "Declarations H7 Table",
        Link: "/declarations_table_h7",
        Title: "Declarations H7 Table",
        isActive: true,
      },
    ],
  },
  profile: {
    title: "Profile",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Profile",
        Link: "/profile",
        Title: "Profile",
        isActive: true,
      },
    ],
  },
  logs: {
    title: "Logs",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Logs",
        Link: "/logs",
        Title: "Logs",
        isActive: true,
      },
    ],
  },
  translations: {
    title: "Translations",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Translations",
        Link: "/translations",
        Title: "Translations",
        isActive: true,
      },
    ],
  },
  duties: {
    title: "Duties",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Duties",
        Link: "/duties",
        Title: "Duties",
        isActive: true,
      },
    ],
  },
};

function RequireAuth({ children, isLogged, office, info, pathName }) {
  const navigator = useNavigate();

  return isLogged ? (
    <>
      <Header info={info} office={office} />
      <div>
        <Sidebar info={info} />
        <div className="page-wrapper">
          <div className="route">
            <div className="header-default">{routesList[pathName].title}</div>
            {routesList[pathName].list.map((route, key) => {
              return (
                <span
                  key={key}
                  onClick={() =>
                    route.isActive === false ? navigator(route.Link) : null
                  }
                >
                  <span
                    className={
                      route.isActive ? "header-active" : "header-inactive"
                    }
                  >
                    {route.Name}
                  </span>
                  {key !== routesList[pathName].list.length - 1 && (
                    <span
                      style={{
                        marginInline: 15,
                        color: "rgba(35,43,53, 0.5)",
                      }}
                    >
                      •
                    </span>
                  )}
                </span>
              );
            })}
          </div>
          <div className="content container-fluid">{children}</div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to={"/login"} />
  );
}

const AppRouter = () => {
  const [isLogged, setIsLogged] = React.useState(null);
  const [info, setInfo] = React.useState(null);
  const [office, setOffice] = React.useState([]);
  const [selectedOfficeID, setSelectedOfficeID] = React.useState(null);
  const [selectedOfficeFunctionality, setSelectedOfficeFunctionality] =
    React.useState([]);

  React.useEffect(() => {
    isUserAuthenticated();
  }, []);

  const checkPermission = (param) => {
    if (info && info.role.includes("ROLE_ADMIN")) {
      return true;
    }
    if (
      selectedOfficeFunctionality.length > 0 &&
      selectedOfficeFunctionality.includes(param)
    ) {
      return true;
    }
    return false;
  };

  async function isUserAuthenticated() {
    const content = await FindMy();
    if (content.status === "ok") {
      setIsLogged(true);
      setInfo(content.data);
      setOffice(content.office);

      const customObject = {
        id: content.office[0].ID,
        name: content.office[0].office_name,
        functionality: content.office[0].functionality,
      };

      if (localStorage.getItem("selected_office")) {
        setSelectedOfficeID(
          JSON.parse(localStorage.getItem("selected_office")).id
        );
        setSelectedOfficeFunctionality(
          JSON.parse(localStorage.getItem("selected_office")).functionality
        );
      } else {
        localStorage.setItem("selected_office", JSON.stringify(customObject));
        setSelectedOfficeID(content.office[0].ID);
        setSelectedOfficeFunctionality(content.office[0].functionality);
      }
    } else {
      localStorage.removeItem("token");
      // localStorage.removeItem("selected_office");
      setIsLogged(false);
      setInfo(null);
      setOffice([]);
    }
  }

  if (isLogged === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="main-wrapper">
      <BrowserRouter>
        <Routes>
          {/* Dashboard start */}
          <Route
            path="/"
            element={
              <RequireAuth
                office={office}
                isLogged={isLogged}
                info={info}
                pathName={"dashboard"}
              >
                <Home info={info} office={office} />
              </RequireAuth>
            }
          />
          <Route
            path="/logs"
            element={
              <RequireAuth
                office={office}
                isLogged={isLogged}
                info={info}
                pathName={"logs"}
              >
                <Logs />
              </RequireAuth>
            }
          />
          {/* Dashboard finish */}
          {checkPermission() && (
            <>
              <Route
                path="/user"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"user"}
                  >
                    <User />
                  </RequireAuth>
                }
              />

              <Route
                path="/user/create"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"create_user"}
                  >
                    <CreateUser />
                  </RequireAuth>
                }
              />

              <Route
                path="/user/:id"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"update_user"}
                  >
                    <UpdateUser />
                  </RequireAuth>
                }
              />

              <Route
                path="/office"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"office"}
                  >
                    <Office />
                  </RequireAuth>
                }
              />

              <Route
                path="/office/:id"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"update_office"}
                  >
                    <UpdateOffice />
                  </RequireAuth>
                }
              />

              <Route
                path="/office/create"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"create_office"}
                  >
                    <CreateOffice />
                  </RequireAuth>
                }
              />
            </>
          )}
          {checkPermission(FUNCTIONALITIES.createImportH7Declaration) && (
            <>
              <Route
                path="/create_import_h7"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"create_import_h7"}
                  >
                    <CreateImportH7Declaration
                      selectedOfficeID={selectedOfficeID}
                      info={info}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/declarations_table_h7"
                element={
                  <RequireAuth
                    office={office}
                    isLogged={isLogged}
                    info={info}
                    pathName={"declarations_table_h7"}
                  >
                    <DeclarationsH7Table selectedOfficeID={selectedOfficeID} />
                  </RequireAuth>
                }
              />
            </>
          )}
          {/* Settings start */}
          {checkPermission(FUNCTIONALITIES.profileOffice) && (
            <Route
              path="/profile"
              element={
                <RequireAuth
                  office={office}
                  isLogged={isLogged}
                  info={info}
                  pathName={"profile"}
                >
                  <Profile selectedOfficeID={selectedOfficeID} />
                </RequireAuth>
              }
            />
          )}

          <Route
            path="/logs"
            element={
              <RequireAuth
                office={office}
                isLogged={isLogged}
                info={info}
                pathName={"logs"}
              >
                <Logs />
              </RequireAuth>
            }
          />
          {checkPermission(FUNCTIONALITIES.Translations) && (
            <Route
              path="/translations"
              element={
                <RequireAuth
                  office={office}
                  isLogged={isLogged}
                  info={info}
                  pathName={"translations"}
                >
                  <Translations selectedOfficeID={selectedOfficeID} />
                </RequireAuth>
              }
            />
          )}
          {checkPermission(FUNCTIONALITIES.Duties) && (
            <Route
              path="/duties"
              element={
                <RequireAuth
                  office={office}
                  isLogged={isLogged}
                  info={info}
                  pathName={"duties"}
                >
                  <Duties selectedOfficeID={selectedOfficeID} />
                </RequireAuth>
              }
            />
          )}
          {/* Settings finish */}
          <Route path="/login" element={<Login />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;

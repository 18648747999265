import React from "react";
import {
  Home,
  Package,
  ChevronDown,
  ChevronUp,
  Grid,
  Settings,
} from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FUNCTIONALITIES } from "../../constants/functionalities";
import { addLog } from "../../services/logs";
import moment from "moment";

export function SidebarNav({ info, router: { location } }) {
  const navigate = useNavigate();
  const [showSettings, setShowSettings] = React.useState(false);
  const [showImport, setShowImport] = React.useState(false);
  const [showAdmin, setShowAdmin] = React.useState(false);

  const adminRole = info.role.includes("ROLE_ADMIN");
  let pathname = location.pathname;

  const logOut = async () => {
    const data = {
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      action: "Logout",
      action_details: "Logged out",
      email: info.email,
      office: JSON.parse(localStorage.getItem("selected_office")).name,
    };

    var jsonData = JSON.stringify(data);

    await addLog(jsonData);

    localStorage.removeItem("token");
    // localStorage.removeItem("selected_office");
    navigate("/login");
  };

  const allowedFunctionalities = (functionalityParam) => {
    if (adminRole) return true;
    const functionality = localStorage.getItem("selected_office");
    if (functionality) {
      const functionalityList = JSON.parse(functionality).functionality;
      if (functionalityList.includes(functionalityParam)) {
        return true;
      }
    }
    return false;
  };

  const handleDropdown = (e) => {
    switch (e) {
      case "settings":
        setShowImport(false);
        setShowAdmin(false);
        break;
      case "import":
        setShowAdmin(false);
        setShowSettings(false);
        break;
      case "admin":
        setShowImport(false);
        setShowSettings(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner">
        <div id="sidebar-menu" className="sidebar-menu">
          <div style={{ marginBottom: 20, color: "rgba(35, 43, 53)" }}>
            Overview
          </div>
          <ul>
            <li>
              <Link to="/" style={{ textDecorationLine: "none" }}>
                <Home />{" "}
                <span
                  style={{
                    fontSize: "SeagoeUI",
                    marginLeft: 20,
                    cursor: "pointer",
                    fontFamily: pathname === "/" ? "SeagoeUIBold" : "SeagoeUI",
                  }}
                >
                  Dashboard
                </span>
              </Link>
            </li>
            {adminRole && (
              <li>
                <a onClick={() => setShowAdmin(!showAdmin)}>
                  <Grid />{" "}
                  <span
                    style={{
                      fontSize: "SeagoeUI",
                      marginLeft: 20,
                      cursor: "pointer",
                    }}
                  >
                    Admin
                  </span>
                  {showAdmin ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showAdmin ? "sidebar-dropdown show" : "sidebar-dropdown  "
                  }
                >
                  <li>
                    <Link
                      to="/user"
                      style={{
                        textDecorationLine: "none",
                        paddingLeft: 60,
                        fontFamily: pathname.includes("/user")
                          ? "SeagoeUIBold"
                          : "SeagoeUI",
                      }}
                      onClick={() => handleDropdown("admin")}
                    >
                      User
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/office"
                      style={{
                        textDecorationLine: "none",
                        paddingLeft: 60,
                        fontFamily: pathname.includes("/office")
                          ? "SeagoeUIBold"
                          : "SeagoeUI",
                      }}
                      onClick={() => handleDropdown("admin")}
                    >
                      Office
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {allowedFunctionalities(
              FUNCTIONALITIES.createImportH7Declaration
            ) && (
              <li>
                <a onClick={() => setShowImport(!showImport)}>
                  <Package />{" "}
                  <span style={{ fontSize: "SeagoeUI", marginLeft: 20 }}>
                    Import
                  </span>
                  {showImport ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showImport ? "sidebar-dropdown show" : "sidebar-dropdown"
                  }
                >
                  {allowedFunctionalities(
                    FUNCTIONALITIES.createImportH7Declaration
                  ) && (
                    <li>
                      <Link
                        to="/create_import_h7"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/create_import_h7" ||
                            pathname === "/declarations_table_h7"
                              ? "SeagoeUIBold"
                              : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("import")}
                      >
                        Create H7 Declaration
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {(adminRole ||
              allowedFunctionalities(FUNCTIONALITIES.profileOffice) ||
              allowedFunctionalities(FUNCTIONALITIES.Translations)) && (
              <li>
                <a onClick={() => setShowSettings(!showSettings)}>
                  <Settings />{" "}
                  <span style={{ fontSize: "SeagoeUI", marginLeft: 20 }}>
                    Settings
                  </span>
                  {showSettings ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showSettings ? "sidebar-dropdown show" : "sidebar-dropdown"
                  }
                >
                  {allowedFunctionalities(FUNCTIONALITIES.profileOffice) && (
                    <li>
                      <Link
                        to="/profile"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/profile"
                              ? "SeagoeUIBold"
                              : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("settings")}
                      >
                        Profile
                      </Link>
                    </li>
                  )}
                  {adminRole && (
                    <li>
                      <Link
                        to="/logs"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/logs" ? "SeagoeUIBold" : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("settings")}
                      >
                        Logs
                      </Link>
                    </li>
                  )}
                  {allowedFunctionalities(FUNCTIONALITIES.Translations) && (
                    <li>
                      <Link
                        to="/translations"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/translations"
                              ? "SeagoeUIBold"
                              : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("settings")}
                      >
                        Translations
                      </Link>
                    </li>
                  )}
                  {adminRole && (
                    <li>
                      <Link
                        to="/duties"
                        style={{
                          textDecorationLine: "none",
                          paddingLeft: 60,
                          fontFamily:
                            pathname === "/duties"
                              ? "SeagoeUIBold"
                              : "SeagoeUI",
                        }}
                        onClick={() => handleDropdown("settings")}
                      >
                        Duties
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
        <div className="overview-sidebar">
          <div className="avatar-sidebar">
            <div className="avatar-top-right">
              <p className="role-sidebar">{info.company_name}</p>
            </div>
            <img
              src={info.photo}
              alt={""}
              width={44}
              height={44}
              style={{ borderRadius: "50%" }}
            />
          </div>
          <p className="name-sidebar">{info.name}</p>
          <p className="email-sidebar">{info.email}</p>
          <div className="button-sidebar" onClick={logOut}>
            <p
              style={{
                color: "#fff",
                fontFamily: "SeagoeUIBold",
                fontSize: "0.8rem",
              }}
            >
              Log out
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(SidebarNav);
